<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>

                <div class="mt-6 flex justify-center dark:text-white" v-if="details.properties.get('logo')">
                    <img :src="getLogo(details.properties.get('logo'))"/>
                </div>
                <p class="text-xl font-bold mt-6 dark:text-white">{{details.properties.get('name')}}</p>
                <p class="text-sm font-bold dark:text-white" v-html="details.properties.get('address')">{{details.properties.get('address')}}</p>
                <p class="text-sm dark:text-white">{{details.properties.get('company_registration_number')}}</p>
                <p class="text-xs mt-3 dark:text-white">{{details.properties.get('vat_number')}}</p>

                <header-link class="my-4" @click="openTab('basics')" :isActive="tabOpen === 'basics'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Details
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('departments')" :isActive="tabOpen === 'departments'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Departments
                        </div>
                    </template>
                </header-link>
<!--                <header-link class="my-4" @click="openTab('projects')" :isActive="tabOpen === 'projects'">-->
<!--                    <template v-slot:title>-->
<!--                        <div Class="text-left">-->
<!--                            Projects-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </header-link>-->
<!--                <header-link class="my-4" @click="openTab('contracts')" :isActive="tabOpen === 'contracts'">-->
<!--                    <template v-slot:title>-->
<!--                        <div Class="text-left">-->
<!--                            Contracts-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </header-link>-->
                <header-link class="my-4" v-if="app === 'hq'" @click="openTab('reoccurring_invoice_items')" :isActive="tabOpen === 'reoccurring_invoice_items'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Reoccurring Invoice Items
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('invoices')" :isActive="tabOpen === 'invoices'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Invoices
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('subscriptions')" :isActive="tabOpen === 'subscriptions'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Packages
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('permissions')" :isActive="tabOpen === 'permissions'" :activeClass="activeClass">
                    <template v-slot:title>
                        <div Class="text-left">
                            Permissions
                        </div>
                    </template>
                </header-link>
            </div>

          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
            <div v-if="tabOpen === 'basics'">
                <client-details-pagination :entity="details" :editing="editing" :updateActionData="updateActionData" :updateAction="updateAction" @editClicked="editClicked" @saveClicked="$emit('saveClicked')"></client-details-pagination>
            </div>
            <div v-if="tabOpen === 'departments'">
                <client-departments-pagination :entities="departments" @success="formSuccess(null)">
                    <template v-slot:additional-buttons>
                        <form-action v-if="addDepartmentAction" :action="addDepartmentAction" actionKey="add-new-department"
                        :redirect-on-success="false" @success="formSuccess(null)">
                        </form-action>
                    </template>
                </client-departments-pagination>
            </div>
<!--            <div v-if="tabOpen === 'projects'">-->
<!--                <client-projects-pagination :entities="projects"></client-projects-pagination>-->
<!--            </div>-->
<!--            <div v-if="tabOpen === 'contracts'">-->
<!--                <client-contracts-pagination :entities="contracts"></client-contracts-pagination>-->

<!--            </div>-->
            <div v-if="app === 'hq' && tabOpen === 'reoccurring_invoice_items'">
                <client-reoccurring-invoice-items-pagination :entities="reoccurringInvoiceItems"></client-reoccurring-invoice-items-pagination>

            </div>
            <div v-if="tabOpen === 'invoices'">
                <client-invoices-pagination :invoices="invoices"></client-invoices-pagination>
            </div>
            <div v-if="tabOpen === 'subscriptions'">
                <client-subscriptions-pagination :entities="subscriptions" @success="reloadPackagesAndReoccurring">
                    <template v-slot:additional-buttons>
                    <form-action
                        v-if="subscriptionDetails.actions.filter(action => action.name === 'create').first()"
                        :action="subscriptionDetails.actions.filter(action => action.name === 'create').first()"
                        action-key="create"
                        :redirect-on-success="false"
                        @success="reloadPackagesAndReoccurring"
                    ></form-action>
                    </template>
                </client-subscriptions-pagination>
            </div>
            <div v-if="tabOpen === 'permissions'">
                <collapsable-header headerColourClass="bg-transparent dark:bg-blue" :collapsable="false" title="Permissions">
                </collapsable-header>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'internal').size > 0" title="Internal" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'internal')"></client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'default').size > 0" title="Default" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'default')"></client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'procurement').size > 0" title="Procure" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'procurement')">
                    <template v-slot:logo>
                        <img class="h-10" src="../../../images/procure_logo.png"/>
                    </template>
                </client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'traqa').size > 0" title="TraQA" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'traqa')">
                    <template v-slot:logo>
                        <img class="h-10" src="../../../images/TraQa_logo.png"/>
                    </template>
                </client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'voucher').size > 0" title="Voucher" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'voucher')">
                    <template v-slot:logo>
                        <img class="h-10" src="../../../images/Vocuher_logo.png"/>
                    </template>
                </client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'on-site-selfie').size > 0" title="On Site" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'on-site-selfie')">
                    <template v-slot:logo>
                        <img class="h-10" src="../../../images/OnSite_logo.png"/>
                    </template>
                </client-permissions-pagination>

                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'costing').size > 0" title="Costing" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'costing')">
                    <template v-slot:logo>
                        <img class="h-10" src="../../../images/Costing_logo.png"/>
                    </template>
                </client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'approvals').size > 0" title="Approvals" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'approvals')"></client-permissions-pagination>
                <client-permissions-pagination @reloadPermissions="getPermissions()" class="my-2" v-if="permissions.filter(entity => entity.entity.properties.get('module') === 'app').size > 0" title="Apps" :entities="permissions.filter(entity => entity.entity.properties.get('module') === 'app')"></client-permissions-pagination>
            </div>
        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
            Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import {mapGetters} from "vuex";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import ClientInvoicesPagination from "@/v3/components/pagination/ClientInvoicesPagination.vue";
import Siren from "super-siren";
import ClientReoccurringInvoiceItemsPagination
    from "@/v3/components/pagination/ClientReoccurringInvoiceItemsPagination.vue";
import ClientProjectsPagination from "@/v3/components/pagination/ClientProjectsPagination.vue";
import ClientDepartmentsPagination from "@/v3/components/pagination/ClientDepartmentsPagination.vue";
import ClientContractsPagination from "@/v3/components/pagination/ClientContractsPagination.vue";
import ClientDetailsPagination from "@/v3/components/pagination/ClientDetailsPagination.vue";
import fileDownload from "js-file-download";
import ClientSubscriptionsPagination from "@/v3/components/pagination/ClientSubscriptionsPagination.vue";
import ClientPermissionsPagination from "@/v3/components/pagination/ClientPermissionsPagination.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'SingleClientDetails',
  components: {
      Modal,
      CollapsableHeader,
      ClientPermissionsPagination,
      ClientSubscriptionsPagination,
      ClientDetailsPagination,
      ClientContractsPagination,
      ClientDepartmentsPagination,
      ClientProjectsPagination,
      ClientReoccurringInvoiceItemsPagination,
      ClientInvoicesPagination,
      DownloadPdf,
    FormAction,
    ConfirmationAction,
    HeaderLink,
    Loading
  },
    data(){
      return {
          editing: false,
          tabOpen: 'basics',
          pdfLoading: false,
          savingItems: false,
          showPDF: false,
          invoices:null,
          reoccurringInvoiceItems: null,
          departments: null,
          projects: null,
          contracts: null,
          subscriptions: null,
          permissions: null,
          showModal: false,
          addDepartmentAction: null,
          subscriptionDetails: null
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        },
        app:{
            type: String,
            default: 'base'
        },
        activeClass:{
            type: String,
            default: null
        }
    },
    mounted() {
      if(this.details) {
          this.getAllDetails();
      }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-client').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'add-new-item' &&
                    action.name !== 'update-client'
                );
        },
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.savingItems) {
                this.savingItems = true;
                this.$refs.items.saveItems().then(res => {
                    this.editing = false;
                    this.savingItems = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The qas were saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingItems = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'delete-invoice'){
                return 'Delete';
            }
            if(name === 'record-partial-payment'){
                return 'Partial Payment';
            }if(name === 'record-full-payment'){
                return 'Full Payment';
            }

            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res && res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }else{
                let entity = {'entity': this.details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.details.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.details.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        getLogo(logo){
            let clientLogo = logo;
            if (logo && logo.startsWith('public/')) {
                clientLogo = logo.replace('public/', '');
            }
            clientLogo = process.env.VUE_APP_API_URL + '/storage/logo/' + clientLogo
            return clientLogo;
        },
         getInvoices(){
            let url = this.details?.links?.filter(link => link.rels.includes('invoices')).first()?.href
            if(url) {
                Siren.get(url).then(res => {
                    this.invoices = res.body.entities.filter(entity => entity.rels.contains('invoice')).reverse();
                })
            }
        },
        getReoccurringInvoiceItems(){
            let url = this.details?.links?.filter(link => link.rels.includes('reoccurring-invoice-items')).first()?.href;
            if(url) {
                Siren.get(url).then(res => {
                    this.reoccurringInvoiceItems = res.body.entities.filter(entity => entity.rels.contains('reoccurring_invoice_items'));
                })
            }
        },
        getDepartments(){
            let url = this.details?.links?.filter(link => link.rels.includes('departments')).first()?.href;
            if(url) {
                Siren.get(url).then(res => {
                    this.addDepartmentAction = res.body.actions.filter(action => action.name === 'add-new-department').first()
                    this.departments = res.body.entities.filter(entity => entity.rels.contains('department'));
                })
            }
        },
        getProjects(){
            let url = this.details?.links?.filter(link => link.rels.includes('projects')).first()?.href;
            if(url) {
                Siren.get(url).then(res => {
                    this.projects = res.body.entities.filter(entity => entity.rels.contains('project'));
                })
            }
        },
        getContracts(){
            let url = this.details.links.filter(link => link.rels.includes('contracts')).first().href;
            if(url) {
                Siren.get(url).then(res => {
                    this.contracts = res.body.entities.filter(entity => entity.rels.contains('site'));
                })
            }
        },
        getSubscriptions(){
            let url = this.details.links.filter(link => link.rels.includes('subscriptions')).first().href;
            if(url) {
                Siren.get(url).then(res => {
                    this.subscriptions = res.body.entities.filter(entity => entity.rels.contains('subscription'));
                    this.subscriptionDetails = res.body;
                })
            }
        },
        getPermissions(){
            let url = this.details.links.filter(link => link.rels.includes('permissions')).first().href;
            if(url) {
                Siren.get(url).then(res => {
                    this.permissions = res.body.entities.filter(entity => entity.rels.contains('permission'));
                })
            }
        },
        getAllDetails(){
            this.getDepartments();
            // this.getProjects();
            // this.getContracts();
            if(this.app === 'hq'){
                this.getReoccurringInvoiceItems();
            }
            this.getInvoices();
            this.getPermissions();
            this.getSubscriptions();
        },
        editClicked(){
            this.editing=true;
        },
        openTab(tabName){
            if(!this.editing){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        },
        reloadPackagesAndReoccurring(){
            this.getSubscriptions();
            this.getReoccurringInvoiceItems();
        }
    },
    watch: {
        details: function (val) {
            if(val !== null) {
                this.getAllDetails();
            }
        }
    }
}
</script>
